<template>
  <section class="container">
    <div class="expired" :class="{'padding-has-caution': isHasCaution}">
      <h1>このページは見つかりません。</h1>

      <router-link :to="{name: 'Index'}">
        TOP画面へ
        <ChevronRightIcon size="24"/>
      </router-link>
    </div>
  </section>
</template>

<script>
// Icon
import { ChevronRightIcon } from 'vue-feather-icons'
import CommonMixin from '@/mixins/common.mixin'
import { mapState } from 'vuex'

export default {
  name: 'PageExpired',

  components: {
    ChevronRightIcon
  },

  mixins: [CommonMixin],

  created () {
    if (this.checkWebview()) {
      this.openPageNative('PageNotFound')
    }
  },
  computed: {
    ...mapState('auth', ['profile', 'token']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  }
}
</script>

<style lang="scss" scoped>
.expired {
  text-align: center;
  padding: 90px 0;
  &.padding-has-caution {
    @media #{$info-phone} {
      padding: 120px 0 90px;
    }
  }
  h1 {
    @include font-size(24px);
    color: $default-green;
    margin-bottom: 105px;
    @media #{$info-phone} {
      margin-bottom: 70px;
    }
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;
    width: 100%;
    border-radius: 6px;
    color: #ffffff;
    background: #6F8784;
    font-family: $font-family-title;
    @include font-size(18px);
    padding: 14px;
    margin: auto;
    cursor: pointer;

    @media #{$info-phone} {
      font-size: 16px;
      padding: 10px;
    }

    svg {
      margin-left: 5px;
    }
  }
}
</style>
